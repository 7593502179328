import React from 'react'
import Section1 from '../components/Section1'

const Home = ({ settingData }) => {

  return (
    <main>
      <Section1 settingData={settingData} />
    </main>
  )
}

export default Home